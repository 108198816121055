import React from "react";

const Button = ({ className, children, onClick, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        padding: "0.75rem 1rem",
        minWidth: "8rem",
        borderStyle: "solid",
        opacity: disabled ? 0.5 : 1,
      }}
      className={className + ""}
    >
      {children}
    </button>
  );
};

export default Button;
