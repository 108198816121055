const deleteToken = () => {
    localStorage.removeItem('auth-token');
};

const getToken = () => {
    return localStorage.getItem('auth-token') ?? "";
};

const setToken = (token) => {
    localStorage.setItem('auth-token', token);
};

const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

const getUser = () => {
    return JSON.parse(localStorage.getItem('user') ?? {});
}
const deleteUser = () => {
    localStorage.removeItem('user');
}


export { getToken, deleteToken, setToken, setUser, getUser, deleteUser };