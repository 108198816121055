import React, { useEffect } from "react";
import BookingCards from "./BookingCard";
import Button from "../../../utils/forms/Button";
import { showModal } from "../../home/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpcomingBookings, setSelectedService } from "../BookingsAction";
import nothingToShow from "../../../assets/images/notingToShow.svg";
import { SET_BOOKING_UUID } from "../../home/ActionTypes";
import moment from "moment-timezone";

function daysUntilBooking(bookingDateStr) {
  const bookingDate = new Date(bookingDateStr);

  const currentDate = moment().tz("Australia/Sydney").toDate();

  const diffTime = bookingDate - currentDate;

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) return "Today";
  return `in ${diffDays || "some"} days`;
}

const Upcomings = () => {
  const dispatch = useDispatch();

  const { upcomingBookings } = useSelector((state) => state.BookingsState);

  useEffect(() => {
    dispatch(fetchUpcomingBookings());
  }, []);

  const getAction = (b) => {
    const bookingDateTime = moment(b.date)
      .tz("Australia/Sydney")
      .set({
        hour: moment(b.timeSlot.startTime).tz("Australia/Sydney").hour(),
        minute: moment(b.timeSlot.startTime).tz("Australia/Sydney").minute(),
      });

    const now = moment().tz("Australia/Sydney");
    const hoursLeft = bookingDateTime.diff(now, "hours");

    return (
      <div className="action-main flex gap-md">
        {hoursLeft > 8 && (
          <>
            <Button
              className={
                "bg-blue-211 round-10 montserrat-semibold f-s-15 white border-transparent"
              }
              onClick={() => {
                dispatch(showModal("bookings-changeDate"));
                dispatch({ type: SET_BOOKING_UUID, payload: b.uuid });
                dispatch(setSelectedService(b.service.uuid));
              }}
            >
              Change Date
            </Button>
            <Button
              className={
                "red-CE2 montserrat-semibold f-s-15 border-transparent bg-white"
              }
              onClick={() => {
                dispatch(showModal("bookings-cancelBooking"));
                dispatch({ type: SET_BOOKING_UUID, payload: b.uuid });
              }}
            >
              Cancel Booking
            </Button>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      {upcomingBookings && upcomingBookings[0] ? (
        upcomingBookings.map((b) => (
          <BookingCards
            key={b.uuid}
            name={`${b.service.serviceName} - ${b.service.sessionTime} Mins`}
            date={moment(b.date).format("D MMM YY")}
            time={`${moment(b.timeSlot.startTime)
              .tz("Australia/Sydney")
              .format("hh:mm A")} - ${moment(b.timeSlot.endTime)
              .tz("Australia/Sydney")
              .format("hh:mm A")}`}
            tip={
              <div
                style={{ padding: "0.3rem 1rem" }}
                className="bg-purple-E3D round-6 f-s-12 tips montserrat-medium"
              >
                {daysUntilBooking(b.date)}
              </div>
            }
            action={getAction(b)}
          />
        ))
      ) : (
        <div className="flex-col justify-center align-center m-auto">
          {" "}
          <img
            src={nothingToShow}
            className="nothing-show-img"
            alt="nothing to show"
          />{" "}
          <h2 className=" montserrat-semibold f-s-20">No Upcoming Bookings</h2>
        </div>
      )}
    </>
  );
};

export default Upcomings;
