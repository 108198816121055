import React, { useEffect } from "react";
import BookingCards from "./BookingCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchPastBookings } from "../BookingsAction";
import nothingToShow from "../../../assets/images/notingToShow.svg";
import moment from "moment-timezone";

const Past = () => {
  const dispatch = useDispatch();

  const { pastBookings } = useSelector((state) => state.BookingsState);

  useEffect(() => {
    dispatch(fetchPastBookings());
  }, []);
  return (
    // <>
    //   <BookingCards
    //     name="Single Booking - 30 Mins"
    //     date="13/7/2024"
    //     time="03:00 pm"
    // tip={
    //   <div className="yellow-CE7 tips card-tip p-2 round-5 montserrat-semibold f-s-12 bg-yellow-FFF">
    //     25.00 Points added to wallet
    //   </div>
    // }
    // action={
    //   <div className="red-CE2 p-2 tips round-5 montserrat-semibold f-s-12 bg-red-FFE">
    //     Your Booking has been Cancelled{" "}
    //   </div>
    // }
    //   />
    //   <BookingCards
    //     name="Single Booking - 30 Mins"
    //     date="13/7/2024"
    //     time="03:00 pm"
    //     action={
    //       <div className="green-0C8 p-2 tips round-5 montserrat-semibold f-s-12 bg-green-DDF">
    //         Session Completed{" "}
    //       </div>
    //     }
    //   />
    // </>
    <>
      {pastBookings && pastBookings[0] ? (
        pastBookings.map((booking) =>
          booking.status === "cancelled" ? (
            <BookingCards
              key={booking.uuid}
              name={`${booking?.service?.serviceName} - ${booking?.service?.sessionTime} Mins`}
              time={moment(booking.timeSlot.startTime)
                .tz("Australia/Sydney")
                .format("hh:mm A")}
              date={moment(booking?.date).format("D MMM YY")}
              tip={
                <div className="yellow-CE7 tips card-tip p-2 round-5 montserrat-semibold f-s-12 bg-yellow-FFF">
                  {booking.amount}.00 Points added to wallet
                </div>
              }
              action={
                <div className="red-CE2 p-2 tips round-5 montserrat-semibold f-s-12 bg-red-FFE">
                  Your Booking has been Cancelled{" "}
                </div>
              }
            />
          ) : (
            <BookingCards
              name={`${booking?.service?.serviceName} - ${booking?.service?.sessionTime} Mins`}
              time={moment(booking.timeSlot.startTime)
                .tz("Australia/Sydney")
                .format("hh:mm A")}
              date={moment(booking?.date).format("D MMM YY")}
              action={
                <div className="green-0C8 p-2 tips round-5 montserrat-semibold f-s-12 bg-green-DDF">
                  Session Completed
                </div>
              }
            />
          )
        )
      ) : (
        <div className="flex-col justify-center align-center m-auto">
          {" "}
          <img
            src={nothingToShow}
            className="nothing-show-img"
            alt="nothing to show"
          />{" "}
          <h2 className=" montserrat-semibold f-s-20">No Past Bookings</h2>
        </div>
      )}
    </>
  );
};

export default Past;
