import React, { useEffect, useState } from "react";
import {
  PaymentForm,
  CreditCard,
  GooglePay,
  ApplePay,
} from "react-square-web-payments-sdk";
import logo from "../../assets/images/logo.svg";
import paymentInstance from "../../utils/PaymentAxios";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { commonPostApi } from "../home/Api";
import { useDispatch } from "react-redux";
import { SAVE_BOOKING_UUID_FOR_PAYMENT, USE_WALLET } from "../bookings/ActionTypes";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ComponentName = (props) => {


  const [booking, setBooking] = useState(false);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch()

  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const response = await commonPostApi("get", `/bookings/amount/${params.bookingUuid}`);
        if (response.data.amount === 0) {
          dispatch({ type: SAVE_BOOKING_UUID_FOR_PAYMENT, payload: null })
          dispatch({ type: USE_WALLET, payload: false })
          navigate("/bookings");
          toast.success("Booking Successful!");
          return;
        }
        setAmount(response.data.amount);

        setBooking(true);
      } catch (error) {
        dispatch({ type: SAVE_BOOKING_UUID_FOR_PAYMENT, payload: null })
        navigate("/bookings");
      }
    }

    fetchBooking();
  }, [params.bookingUuid, navigate]);



  if (!booking) {
    return (
      <div style={{ height: "100vh" }} className="w-full h-full flex justify-center align-center"><Spinner /></div>
    );
  }

  return (
    <div className="container payment-main-container ">
      <div className="flex gap-10 relative">
        <div className="flex align-center pointer" onClick={() => {
          navigate("/");
          dispatch({ type: SAVE_BOOKING_UUID_FOR_PAYMENT, payload: null })
        }}><ArrowBackIosIcon fontSize="large" /></div>    <img src={logo} alt="" />
      </div>
      <div>
        <PaymentForm
          applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
          locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
          createPaymentRequest={() => ({
            countryCode: "AU",
            currencyCode: "AUD",
            total: {
              amount: String(amount),
              label: "Total",
            },
          })}
          cardTokenizeResponseReceived={async (token, buyer) => {
            const response = await paymentInstance.post("/payment", {
              sourceId: token.token,
              bookingUuid: params.bookingUuid,
            });

            if (response.data.success) {
              toast.success("Booking Successful!");
              dispatch({ type: SAVE_BOOKING_UUID_FOR_PAYMENT, payload: null })
              dispatch({ type: USE_WALLET, payload: false })
              navigate("/bookings")
            } else {
              toast.error("Payment Failed! If deducted, Amount will be refunded shortly");
            }
          }}
        >
          <GooglePay />
          <p></p>
          {/* <ApplePay /> */}
          {/* <p></p> */}
          <p>-----------------------OR-----------------------</p>
          <p></p>
          <CreditCard />
        </PaymentForm>
      </div>
    </div>
  );
};

export default ComponentName;
