export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SELECTED_TIME_SLOT = "SET_SELECTED_TIME_SLOT";

export const SET_PAST_BOOKINGS = "SET_PAST_BOOKINGS";
export const SET_UPCOMING_BOOKINGS = "SET_UPCOMING_BOOKINGS";
export const SAVE_BOOKING_UUID_FOR_PAYMENT = "SAVE_BOOKING_UUID_FOR_PAYMENT";

export const CHANGE_USER_PROFILE = "CHANGE_USER_PROFILE";
export const SET_PRICE = "SET_PRICE";

export const USE_WALLET = "USE_WALLET";

