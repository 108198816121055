import React from "react";
import { Footer, Header } from "../layout/Layout";
import Main from "./components/Main";

const ComponentName = (props) => {
  return (
    <div className="container-standard bookings-main-container overflow-scroll ">
      <Header className={"bookings-header"} />
      <Main className={"bookings-main"} />
    </div>
  );
};

export default ComponentName;
