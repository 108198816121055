import axios from 'axios';

const paymentInstance = axios.create(
    {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        }
    }
);
paymentInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_SQUARE_ACCESS_TOKEN}`;
    return config;
});


export default paymentInstance;