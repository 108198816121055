import React, { useEffect } from "react";
import Main from "./components/Main";
import { Footer, Header } from "../layout/Layout";
import Paynow from "./components/Paynow";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ComponentName = (props) => {
  const navigate = useNavigate();

  const { bookingUuid } = useSelector((state) => state.BookingsState);
  useEffect(() => {
    if (bookingUuid) {
      navigate(`/payments/${bookingUuid}`);
    }
  }, [bookingUuid]);
  return (
    <div className="">
      <div className=" overflow-scroll home-main-container  ">
        <Header className={"home-header container"} />
        <Main className={"home-main container"} />
        <Footer className={""} />
      </div>
      <Paynow />
    </div>
  );
};

export default ComponentName;
