import { registerApi, loginApi, commonPreApi, commonPostApi } from "./Api"
import { SET_WALLET_BALANCE, SET_MODAL, CLEAR_MODAL, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, CLEAR_ERRORS, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SAVE_USER_DATA_SUCCESS, SET_SERVICES, SET_USER_DATA, SET_SERVICE_DETAILS } from "./ActionTypes"
import { setToken } from "../../utils/ManageToken"
import { login } from "../layout/LayoutAction"
import { toast } from "react-toastify"
import { CHANGE_USER_PROFILE } from "../bookings/ActionTypes"

export const showModal = (modal) => (dispatch) => {
    dispatch({ type: SET_MODAL, payload: modal })
}
export const hideModal = () => (dispatch) => {
    dispatch({ type: CLEAR_MODAL })
}

export const registerOTPrequest = (data) => async (dispatch) => {
    try {
        await registerApi('users/register/request-otp', { emailAddress: data?.emailAddress });
        dispatch({ type: REGISTER_REQUEST, payload: { emailAddress: data?.emailAddress } });
        toast.success("Code sent successfully");
    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error?.response?.data?.message });
    }
}

export const regiterResendOPTrequest = (data) => async (dispatch) => {
    try {
        await registerApi('/users/register/resend-otp', { emailAddress: data?.emailAddress });
        dispatch({ type: REGISTER_REQUEST, payload: { emailAddress: data?.emailAddress } });
        toast.success("Code sent successfully");
    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error?.response?.data?.message });
    }
}
export const loginResendOPTrequest = (data) => async (dispatch) => {
    try {
        await registerApi('/users/login/resend-otp', { emailAddress: data?.emailAddress });
        dispatch({ type: REGISTER_REQUEST, payload: { emailAddress: data?.emailAddress } });
        toast.success("Code sent successfully");
    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error?.response?.data?.message });
    }
}

export const registerOTPverify = (data) => async (dispatch) => {
    try {
        await registerApi('users/register/verify-otp', { emailAddress: data?.emailAddress, otp: data?.otp });
        dispatch({ type: REGISTER_SUCCESS });
    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error?.response?.data?.message });
    }
}

export const saveRegisterData = (data) => async (dispatch) => {
    try {
        const res = await registerApi('users/register/save', { emailAddress: data?.emailAddress, firstName: data?.firstName, lastName: data?.lastName, phone: data?.phone });
        setToken(res.data.token);
        dispatch({ type: SAVE_USER_DATA_SUCCESS, payload: res.data });
        dispatch(login());
        toast.success("Registered successfully");
    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error.response.data });
    }
}

export const loginOTPRequest = (data) => async (dispatch) => {
    try {
        await loginApi('users/login/request-otp', { emailAddress: data?.emailAddress });
        dispatch({ type: LOGIN_REQUEST, payload: { emailAddress: data?.emailAddress } });
        toast.success("Code sent successfully");
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error?.response?.data?.message });
    }
}

export const loginOTPVerify = (data) => async (dispatch) => {
    try {
        const res = await loginApi('users/login/verify-otp', { emailAddress: data?.emailAddress, otp: data?.otp });
        setToken(res.data.token);
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch(showModal(null));
        dispatch(login());
        toast.success("Logged in successfully");
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error?.response?.data?.message || "Something went wrong" });
    }
}

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}

export const getServicesOptions = () => async (dispatch) => {
    try {
        const res = await commonPreApi('get', 'services');
        dispatch({ type: SET_SERVICES, payload: res.data });
    } catch (error) {
        dispatch({ type: SET_SERVICES, payload: { list: [] } });
        toast.error("Something went wrong");
    }
}

export const getWalletBalance = () => async (dispatch) => {
    try {
        const res = await commonPostApi('get', '/users/wallet');
        dispatch({ type: SET_WALLET_BALANCE, payload: res.data });
    } catch (error) {
        toast.error("Something went wrong");
        dispatch({ type: SET_WALLET_BALANCE, payload: null });
    }
}

export const changeUserProfile = (data) => async (dispatch) => {
    try {
        await commonPostApi('patch', '/users', { firstName: data?.firstName, lastName: data?.lastName, phone: data?.phone });
        dispatch({ type: CHANGE_USER_PROFILE, payload: data })
        toast.success("Profile updated successfully");
    } catch (error) {
        toast.error("Failed to update profile!");
    }
}

export const fetchUserProfile = () => async (dispatch) => {
    try {
        const res = await commonPostApi('get', '/users/findone');
        dispatch({ type: SET_USER_DATA, payload: res.data });
    } catch (error) {
        toast.error("Failed to fetch user data!");
    }
}

export const fetchServiceDetails = () => async (dispatch) => {
    try {
        const res = await commonPreApi('get', `/services/service-details`);
        dispatch({ type: SET_SERVICE_DETAILS, payload: res.data });
    } catch (error) {
        toast.error("Failed to fetch service details!");
    }
}   