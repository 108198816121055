import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import Button from "../../utils/forms/Button";
import BasicModal from "../../utils/commonComponents/Modal";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showModal } from "../home/HomeAction";
import { login, logout } from "./LayoutAction";
import hamburger from "../../assets/images/hamburger.svg";
import walletIcon from "../../assets/images/wallet.svg";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { getToken } from "../../utils/ManageToken";
import { toast } from "react-toastify";

export const Header = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wallet } = useSelector((state) => state.UserState);
  const { login: logIn } = useSelector((state) => state.LayoutState);
  const { modal } = useSelector((state) => state.HomeState);

  useEffect(() => {
    getToken() ? dispatch(login()) : dispatch(logout());
  }, [dispatch]);
  return (
    <>
      <div
        className={
          className + " flex justify-content-between align-center header-main"
        }
      >
        <div>
          <img
            src={logo}
            alt="logo"
            onClick={() => navigate("/")}
            className="logo pointer"
          />
        </div>
        {!logIn ? (
          <div>
            <Button
              className={
                "rounded-20 bg-purple-E3D blue-211 w-10 border montserrat-semibold f-s-16"
              }
              onClick={() => dispatch(showModal("home-login"))}
            >
              LOG IN
            </Button>
          </div>
        ) : (
          <div className="flex gap-md after-login">
            <Button
              className={
                "bg-purple-E3D button border-transparent round-20 montserrat-semibold f-s-16 flex gap-sm align-center"
              }
            >
              <>
                <img src={walletIcon} className="wallet" alt="" />
                {`${Number(wallet)}.00` || <Spinner />}
              </>
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                className={
                  "bg-white border-black button dropdown relative round-20 "
                }
              >
                <div className="w-full">
                  <img src={hamburger} className="burger " alt="" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="open">
                <Dropdown.Item
                  onClick={() => {
                    navigate("/bookings");
                  }}
                >
                  My Bookings
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(logout());
                    toast.success("Logged out successfully");
                  }}
                >
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <BasicModal
        className={"w-fit"}
        open={modal}
        handleClose={() => dispatch(hideModal())}
      />
    </>
  );
};

export const Footer = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        className +
        " footer flex justify-content-between align-center bg-grey-D9D"
      }
    >
      {/* todo */}
      <div
        className="montserrat-medium f-s-15 pointer flex gap-10 left"
        onClick={() => navigate("/terms-conditions")}
      >
        Terms and Conditions
        <div>|</div>
        <div className="montserrat-medium f-s-15">
          Copyright @Recovery zone{" "}
        </div>
      </div>
      <Link
        target="_blank"
        to="https://techsphere.co.in"
        className="montserrat-medium f-s-15 black right"
      >
        {" "}
        Crafted by @Techsphere
      </Link>
    </div>
  );
};
