import { getToken } from "../../utils/ManageToken";
import { LOGIN_SUCCESS, LOG_OUT } from "./ActionTypes";
const INITIAL_STATE = {
    login: false
};
const LayoutReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            getToken() && (action.payload = true);
            return { ...state, login: action.payload };
        case LOG_OUT:
            return { ...state, login: false };
        default:
            return state
    }
};
export default LayoutReducer