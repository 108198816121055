import React, { useEffect, useState } from "react";
import Input from "../../forms/Input";
import Button from "../../forms/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  registerOTPrequest,
  registerOTPverify,
  regiterResendOPTrequest,
  showModal,
} from "../../../app/home/HomeAction";
import { toast } from "react-toastify";
import { emailRegex } from "../../Constant";
import { CLEAR_ERRORS, CLEAR_LOGIN_STATE } from "../../../app/home/ActionTypes";

const HomeSignup = () => {
  const dispatch = useDispatch();
  const [verify, setVerify] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [validateEmailError, setValidateEmailError] = useState(false);

  const { error, loading, otpVerified, emailAddress } = useSelector(
    (state) => state.LoginState
  );

  const handleSendOTP = () => {
    if (!emailRegex.test(email)) return toast.error("Invalid Email Address");
    dispatch(registerOTPrequest({ emailAddress: email }));
    if (!loading && !error) {
      setVerify(false);
      setTimer(30); // Set the timer to 30 seconds when the OTP is sent
    }
  };

  const handleResendOTP = () => {
    dispatch(regiterResendOPTrequest({ emailAddress: emailAddress }));
    setTimer(30); // Reset the timer to 30 seconds on resend
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: CLEAR_ERRORS });
    }
    if (otpVerified) {
      dispatch(showModal("home-personalDetails"));
    }
  }, [error, dispatch, otpVerified, email]);

  useEffect(() => {
    if (!email) return setValidateEmailError(false);
    if (emailRegex.test(email)) setValidateEmailError(false);
    else setValidateEmailError("Email is not valid!");
  }, [email]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleVerifyOTP = () => {
    if (!otp) return toast.error("Code is required");
    dispatch(registerOTPverify({ emailAddress: email, otp }));
  };

  return (
    <div className="home-login-main w-28 flex flex-col gap-md">
      <div className="">
        <h3 className="f-s-14 montserrat-semibold">SIGN UP</h3>
        <p className="f-s-14 montserrat-medium">
          Enter your email to sign up. We’ll send an Code to verify your email
          and create your account.
        </p>
      </div>
      {verify ? (
        <>
          <div className="">
            <Input
              important={true}
              heading={"Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={
                validateEmailError && (
                  <p className="red-CE2 p-2">{validateEmailError}</p>
                )
              }
            />
          </div>
          <div className="flex gap-md align-center">
            <Button
              className={
                "bg-blue-211 white round-10 montserrat-semibold f-s-15"
              }
              onClick={handleSendOTP}
            >
              Send Code
            </Button>
            <p
              className="m-0 blue-211 montserrat-semibold f-s-12 underline pointer"
              onClick={() => {
                dispatch(showModal("home-login"));
                dispatch({ type: CLEAR_LOGIN_STATE });
              }}
            >
              Registered already? LOG IN
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="">
            <Input
              heading={"Code"}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div className="flex gap-md align-center">
            <Button
              className={
                "bg-blue-211 white round-10 montserrat-semibold f-s-15"
              }
              onClick={handleVerifyOTP}
            >
              Confirm
            </Button>
            <Button
              className={
                "blue-211 bg-transparent border-transparent montserrat-semibold f-s-15"
              }
              onClick={handleResendOTP}
              disabled={timer > 0}
            >
              {timer > 0 ? `Resend Code in ${timer}s` : "Resend Code"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeSignup;
