import * as React from "react";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";

import HomeLogin from "./modals/home-login";
import HomeSignup from "./modals/home-signup";
import HomeServiceDetails from "./modals/home-serviceDetails";
import HomePersonalDetails from "./modals/home-personalDetails";

import BookingsChangeDate from "./modals/bookings-changeDate";
import BookingsCancelBooking from "./modals/bookings-cancelBooking";
import BookingEditProfile from "./modals/booking-edit-profile";

export default function BasicModal({
  open,
  handleClose,
  handleOpen,
  children,
  style,
  className,
}) {
  const sx = {
    position: "absolute",
    left: "50%",
    top: "50%",
    translate: "-50% -50%",
    minWidth: "25%",
    maxWidth: "50%",
    maxHeight: "90%",
    backgroundColor: "white",
    borderRadius: "8px",
    outline: "none",
    overflow: "auto",
    padding: "2.75rem 1.5rem",
    ...style,
  };

  const renderContent = () => {
    switch (open) {
      case "home-signup":
        return <HomeSignup />;
      case "home-login":
        return <HomeLogin />;
      case "home-serviceDetails":
        return <HomeServiceDetails />;
      case "home-personalDetails":
        return <HomePersonalDetails />;
      case "bookings-changeDate":
        return <BookingsChangeDate />;
      case "bookings-cancelBooking":
        return <BookingsCancelBooking />;
      case "booking-edit-profile":
        return <BookingEditProfile />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Modal
        open={Boolean(open)}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="w-full h-full">
          <div className={"modal-box " + className} style={sx}>
            {children ? children : renderContent()}
            <div
              style={{ position: "absolute", top: "1rem", right: "1.5rem" }}
              className="f-s-19 pointer"
              onClick={() => handleClose()}
            >
              <ClearIcon />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
