import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { styled } from "@mui/material";
import dayjs from "dayjs";

const StyledPickersLayout = styled(DateCalendar)({
  ".MuiDateCalendar-root": {
    backgroundColor: "#F9F9F9",
  },
  ".MuiPickersDay-today": {},
  ".MuiPickersCalendarHeader-label": {
    fontSize: "15px",
    fontFamily: "Montserrat-Semibold, sans-serif",
  },
  ".MuiDayCalendar-weekDayLabel": {
    fontSize: "0.75rem",
    color: "rgba(60, 60, 67, 0.60)",
    fontFamily: "Montserrat-Regular, sans-serif",
  },
  ".MuiPickersDay-root": {
    fontSize: "1rem",
    padding: "1px",
    fontFamily: "Montserrat-Regular, sans-serif",
    ":hover": {
      backgroundColor: "#E3DCFF",
    },
    ":focus": { backgroundColor: "#E3DCFF" },
    "&.Mui-selected": {
      backgroundColor: "#E3DCFF",
      fontWeight: "600",
      color: "black",
      ":hover": {
        backgroundColor: "#E3DCFF",
      },
      ":focus": { backgroundColor: "#E3DCFF" },
    },
  },
  ".MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#E3DCFF",
    color: "black",
    fontWeight: "600",
    ":hover": {
      backgroundColor: "#E3DCFF",
    },
    ":focus": { backgroundColor: "#E3DCFF" },
  },
  "MuiIconButton-root": {
    ":hover": {
      borderColor: "#E3DCFF",
    },
    ":focus": { backgroundColor: "#E3DCFF" },
  },
  ".css-innj4t-MuiPickersYear-yearButton.Mui-selected:focus, .css-innj4t-MuiPickersYear-yearButton.Mui-selected":
    {
      backgroundColor: "#E3DCFF",
      color: "black",
    },
});

export default function BasicDateCalendar({ beforeDisabled, value, onChange }) {
  const shouldDisableDate = (date) => {
    return dayjs(date).isBefore(dayjs(), "day");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledPickersLayout
        value={value && dayjs(value)}
        onChange={(e) => {
          onChange(e.format("YYYY-MM-DD"));
        }}
        shouldDisableDate={beforeDisabled ? shouldDisableDate : () => {}}
      />
    </LocalizationProvider>
  );
}
