import { CHANGE_USER_PROFILE } from "../bookings/ActionTypes";
import { SET_SERVICE_DETAILS, SET_TIME_SLOTS, SET_SERVICES, SET_MODAL, CLEAR_MODAL, CLEAR_LOGIN_STATE, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, CLEAR_ERRORS, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SAVE_USER_DATA_SUCCESS, SET_WALLET_BALANCE, SET_USER_DATA, SET_BOOKING_UUID } from "./ActionTypes"
const INITIAL_STATE = {
    modal: null,
};
export const HomeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MODAL:
            return { ...state, modal: action.payload };
        case CLEAR_MODAL:
            return { ...state, modal: null };
        default:
            return state
    }
};

const LOGIN_INITIAL_STATE = {
    token: null,
    loading: false,
    otpVerified: false,
    emailAddress: "",
}

export const LoginReducer = (state = LOGIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER_REQUEST:
        case LOGIN_REQUEST:
            return { ...state, loading: true, emailAddress: action.payload?.emailAddress };
        case LOGIN_SUCCESS:
        case SAVE_USER_DATA_SUCCESS:
            return { ...state, loading: false, token: action.payload?.token };
        case REGISTER_SUCCESS:
            return { ...state, loading: false, otpVerified: true };
        case REGISTER_FAILURE:
        case LOGIN_FAILURE:
            return { ...state, loading: false, error: action.payload, otpVerified: false, token: null };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        case CLEAR_LOGIN_STATE:
            return { ...LOGIN_INITIAL_STATE };
        default:
            return state;
    }
}

export const ServiceOptionsReducer = (state = { services: [], timeSlots: [], serviceDetails: [] }, action) => {
    switch (action.type) {
        case SET_SERVICES:
            return { ...state, services: action.payload?.list };
        case SET_TIME_SLOTS:
            return { ...state, timeSlots: action.payload?.list };
        case SET_SERVICE_DETAILS:
            return { ...state, serviceDetails: action.payload?.list };
        case SET_BOOKING_UUID:
            return { ...state, uuid: action.payload };
        default:
            return state;
    }
}
export const UserReducer = (state = { wallet: null }, action) => {
    switch (action.type) {
        case SET_WALLET_BALANCE:
            return { ...state, wallet: action.payload?.walletAmount };
        case CHANGE_USER_PROFILE:
            return { ...state, firstName: action.payload?.firstName, lastName: action.payload?.lastName };
        case SET_USER_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}