import { combineReducers } from 'redux';
/* PLOP_INJECT_REDUCER_IMPORT */
import { TermsReducer } from '../app/terms';
import { PaymentReducer } from '../app/payment';
import { BookingsReducer } from '../app/bookings';
import { LayoutReducer } from '../app/layout';
import { HomeReducer, LoginReducer } from '../app/home';
import { ServiceOptionsReducer, UserReducer } from '../app/home/HomeReducer';


export default combineReducers({
    /* PLOP_INJECT_REDUCER */
	TermsState: TermsReducer,
	PaymentState: PaymentReducer,
    BookingsState: BookingsReducer,
    LayoutState: LayoutReducer,
    HomeState: HomeReducer,
    LoginState: LoginReducer,
    ServiceState: ServiceOptionsReducer,
    UserState: UserReducer,
});