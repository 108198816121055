import React, { useEffect } from "react";
import SlotPicker from "../../../utils/commonComponents/date/Slot";
import Calander from "../../../utils/commonComponents/date/Calender";
import dayjs from "dayjs";
import {
  fetchTimeSlots,
  setSelectedDate,
  setSelectedTimeSlot,
} from "../../bookings/BookingsAction";
import { useDispatch, useSelector } from "react-redux";
const DateSlot = ({ className, style }) => {
  const dispatch = useDispatch();

  const { date, timeSlotUuid } = useSelector((state) => state.BookingsState);
  const { timeSlots } = useSelector((state) => state.ServiceState);

  const timeSlotOptions = timeSlots?.map((i) => {
    return {
      uuid: i.uuid,
      time: i.startTime,
      available: !i.isBooked,
    };
  });
  useEffect(() => {
    dispatch(fetchTimeSlots());
  }, []);
  return (
    <div
      className={
        "date-slot-container border rounded-10 flex gap-md" + className
      }
      style={style}
    >
      <div className="date-selector-main">
        <h5 className="montserrat-semibold heading f-s-20">Select your Date</h5>
        <div>
          <Calander
            beforeDisabled={true}
            value={date}
            onChange={(e) => {
              dispatch(setSelectedDate(e));
            }}
          />
        </div>
      </div>
      <div className="slot-selector-main">
        <h5 className="montserrat-semibold heading f-s-20">Select slot</h5>
        <div className="slot-picker">
          <SlotPicker
            className={"slot-picker"}
            options={timeSlotOptions}
            selected={timeSlotUuid}
            onClick={(e) => dispatch(setSelectedTimeSlot(e))}
          />
        </div>
      </div>
    </div>
  );
};

export default DateSlot;
