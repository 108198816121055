import { deleteToken, getToken } from "../../utils/ManageToken"
import { fetchUserProfile, getWalletBalance } from "../home/HomeAction"
import { LOGIN_SUCCESS, LOG_OUT } from "./ActionTypes"

export const login = () => (dispatch) => {
    getToken() ? (dispatch({ type: LOGIN_SUCCESS, payload: true }) && dispatch(getWalletBalance()) && dispatch(fetchUserProfile())) : dispatch({ type: LOGIN_SUCCESS, payload: false })
}

export const logout = () => (dispatch) => {
    deleteToken();
    localStorage.clear();
    dispatch({ type: LOG_OUT })
}