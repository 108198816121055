import React, { useEffect, useState } from "react";
import Button from "../../../utils/forms/Button";
import RadioSelector from "./RadioSelector";
import { getServicesOptions, showModal } from "../HomeAction";
import { useDispatch, useSelector } from "react-redux";
import {
  createBooking,
  setSelectedService,
  setUseWallet,
} from "../../bookings/BookingsAction";
import toolTipIcon from "../../../assets/images/tooltip.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

const ServiceOptions = () => {
  const {
    serviceUuid: selected,
    price,
    useWallet,
  } = useSelector((state) => state.BookingsState);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const { serviceUuid, date, timeSlotUuid } = useSelector(
    (state) => state.BookingsState
  );
  const { wallet, uuid } = useSelector((state) => state.UserState);
  const { services } = useSelector((state) => state.ServiceState);

  const handleSetwallet = (e) => {
    dispatch(setUseWallet(e.target.checked));
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your payment amount will be converted into points, which can be used for
      future bookings and services
    </Tooltip>
  );

  const handleBooking = () => {
    if (!uuid) {
      dispatch(showModal("home-login"));
      return;
    }
    dispatch(
      createBooking({
        serviceUuid,
        userUuid: uuid,
        timeSlotUuid,
        isWallet: useWallet,
        date,
      })
    );
  };

  useEffect(() => {
    dispatch(getServicesOptions());
  }, []);

  useEffect(() => {
    if (services?.length > 0) {
      setOptions(
        services?.map((service) => ({
          service: `${service?.serviceName} - ${service?.sessionTime} Mins`,
          description: service?.description,
          price: `$${service?.price} AUD`,
          value: service?.price,
          uuid: service?.uuid,
        }))
      );
    }
  }, [services]);
  return (
    <div className="service-container flex-col gap-sm w-34">
      <div className="service-head">
        <h5 className="montserrat-semibold f-s-20 service-heading">
          Service Options
        </h5>
      </div>
      <div className="service-main">
        <RadioSelector
          options={options}
          selected={selected}
          setSelected={(uuid, price) => {
            dispatch(setSelectedService(uuid, price));
          }}
        />
      </div>
      <div className="service-foot flex gap-sm">
        <p className="montserrat-medium f-s-10">
          Booking Options - Includes use of both Infrared Sauna & Cold Plunge
          Bath*{" "}
        </p>
        <p
          className="montserrat-medium f-s-10 underline blue-211 pointer"
          onClick={() => dispatch(showModal("home-serviceDetails"))}
        >
          View more
        </p>
      </div>
      {uuid && serviceUuid && date && timeSlotUuid && (
        <div className="flex-col gap-10">
          {wallet ? (
            <div className="flex gap-2 ">
              <input
                type="checkbox"
                name="wallet"
                id="wallet"
                onChange={handleSetwallet}
              />
              <label htmlFor="wallet" className="f-s-15 montserrat-medium">
                Use {wallet > price ? price : wallet}.00 from Wallet{" "}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <img src={toolTipIcon} draggable={true} alt="" />
                </OverlayTrigger>
              </label>
            </div>
          ) : (
            ""
          )}
          <p className="f-s-15 montserrat-semibold">
            Total:{" "}
            {(useWallet ? price - wallet : price) > 0
              ? useWallet
                ? price - wallet
                : price
              : "0"}
            .00 AUD
          </p>
        </div>
      )}
      <div className="service-booking-button">
        <Button
          className={"bg-blue-211 w-fit white border rounded-10"}
          disabled={!(serviceUuid && date && price && timeSlotUuid)}
          onClick={handleBooking}
        >
          Pay Now
        </Button>
      </div>
    </div>
  );
};

export default ServiceOptions;
