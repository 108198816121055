import React from "react";
import { Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const RadioSelector = ({ options = [], selected, setSelected }) => {
  if (options.length === 0)
    return (
      <Container className="flex flex-wrap">
        {[1, 2, 3, 4].map((i, idx) => (
          <Row className="flex-col p-2" style={{ width: "50%" }} key={idx}>
            <Skeleton count={1}></Skeleton>
            <div className="" style={{ padding: "2px", paddingLeft: "2rem" }}>
              <Skeleton count={2}></Skeleton>
            </div>
            <div style={{ width: "4rem", marginLeft: "2rem" }}>
              <Skeleton count={1}></Skeleton>
            </div>
          </Row>
        ))}
      </Container>
    );
  return (
    <div className="flex flex-wrap gap-sm radio-selector">
      {options &&
        options[0] &&
        options?.map((option) => (
          <div
            key={option.uuid}
            className={`border-1 pointer radio-main ${
              selected === option.uuid ? "border-blue-211" : "border-grey-D9D"
            } relative round-5 flex-col gap-sm`}
            onClick={() => {
              setSelected(option.uuid, option.value);
            }}
          >
            <div className="f-s-15 montserrat-medium white-space-nowrap">
              {option.service && option.service}
            </div>
            <div className="f-s-10 montserrat-regular">
              {option.description && option.description}
            </div>
            <div className="f-s-15 montserrat-bold">
              {option.price && option.price}
            </div>
            <div
              style={{ top: "50%", transform: "translateY(-50%)" }}
              className={`absolute w-1 h-1  round-100 left-10 ${
                selected === option.uuid ? "bg-blue-211" : "bg-grey-D9D"
              }`}
            ></div>
          </div>
        ))}
    </div>
  );
};

export default RadioSelector;
