export const SET_MODAL = 'SET_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';


export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS';

export const SET_WALLET_BALANCE = "SET_WALLET_BALANCE";
export const SET_USER_DATA = "SET_USER_DATA";

export const SET_SERVICES = "SET_SERVICES";
export const SET_TIME_SLOTS = "SET_TIME_SLOTS";
export const SET_SERVICE_DETAILS = "SET_SERVICE_DETAILS";

export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SET_BOOKING_UUID = 'SET_BOOKING_UUID';
