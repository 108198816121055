import React from "react";

const Input = ({
  heading,
  placeholder,
  important,
  onChange,
  value,
  type,
  error,
}) => {
  return (
    <div>
      <p className="montserrat-regular f-s-14 m-0">
        {heading && `${heading} ${important ? "*" : ""}`}
      </p>
      {type === "text-area" ? (
        <textarea
          className="input"
          cols={10}
          type="text"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      ) : (
        <input
          className="input"
          type="text"
          onChange={(e) => onChange(e)}
          value={value}
          placeholder={placeholder}
        />
      )}
      {error ? error : ""}
    </div>
  );
};

export default Input;
