import React from "react";
import ServiceOptions from "./ServiceOptions";
import DateSlot from "./DateSlot";

const Main = ({ className }) => {
  return (
    <div className={className + ""}>
      <div className="w-full">
        <h1 className="m-auto form-legend text-center montserrat-semibold f-s-20">
          Recovery Zone Wollongong Booking Form
        </h1>
      </div>
      <div className="flex booking-main justify-center gap-xl aligin-center">
        <ServiceOptions />
        <DateSlot />
      </div>
    </div>
  );
};

export default Main;
