import React, { useState, useRef, useEffect } from "react";
import Upcomings from "./Upcomings";
import Past from "./Past";
import editLogo from "../../../assets/images/Edit.svg";
import { Footer } from "../../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../home/HomeAction";

const Main = ({ className }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("upcoming");
  const underlineRef = useRef(null);
  const upcomingRef = useRef(null);
  const pastRef = useRef(null);

  const { firstName, lastName, emailAddress, phone } = useSelector(
    (s) => s.UserState
  );

  useEffect(() => {
    const activeElement =
      activeTab === "upcoming" ? upcomingRef.current : pastRef.current;
    const underline = underlineRef.current;

    if (activeElement && underline) {
      underline.style.width = `${activeElement.offsetWidth}px`;
      underline.style.left = `${activeElement.offsetLeft}px`;
    }
  }, [activeTab]);

  return (
    <div className={" " + className}>
      <div className="main-head-container relative flex-col justify-content-center">
        <div className="head-user-info">
          <h4 className="montserrat-semibold f-s-16">
            Hello, {`${firstName || "User"} ${lastName || ""}`}
            <img
              src={editLogo}
              onClick={() => dispatch(showModal("booking-edit-profile"))}
              className="edit-logo pointer"
              alt=""
            />
          </h4>
          <p className="montserrat-medium f-s-12 mb-0">{emailAddress}</p>
          <p className="montserrat-medium f-s-12">{phone}</p>
        </div>
      </div>
      <div className="head-upcoming-main round-10 bg-white h-40">
        <div className="flex gap-lg tabs fixed">
          <div
            className={`montserrat-medium tab ${
              activeTab === "upcoming" ? "active" : ""
            }`}
            onClick={() => setActiveTab("upcoming")}
            ref={upcomingRef}
          >
            UPCOMING
          </div>
          <div
            className={`montserrat-medium tab ${
              activeTab === "past" ? "active" : ""
            }`}
            onClick={() => setActiveTab("past")}
            ref={pastRef}
          >
            PAST
          </div>
          <div className="underline" ref={underlineRef} />
        </div>
        <div className="flex-col h-30 align-center upcoming-cards overflow-scroll">
          {activeTab === "upcoming" ? <Upcomings /> : <Past />}
        </div>
      </div>
      <Footer className={"bookings-footer"} />
    </div>
  );
};

export default Main;
