import preLoginInstance from "../../utils/PreLoginAxios";
import postLoginInstance from "../../utils/PostLoginAxios";



export const registerApi = async (url, data) => {
    return await preLoginInstance.post(url, data);
}



export const loginApi = async (url, data) => {
    return await preLoginInstance.post(url, data);
}

export const commonPreApi = (method, url, data) => {
    switch (method) {
        case "get":
            return preLoginInstance.get(url);
        case "post":
            return preLoginInstance.post(url, data);
        case "put":
            return preLoginInstance.put(url, data);
        case "delete":
            return preLoginInstance.delete(url);
        case "patch":
            return preLoginInstance.patch(url, data);
        default:
            return preLoginInstance;
    }
}

export const commonPostApi = (method, url, data) => {
    switch (method) {
        case "get":
            return postLoginInstance.get(url);
        case "post":
            return postLoginInstance.post(url, data);
        case "put":
            return postLoginInstance.put(url, data);
        case "delete":
            return postLoginInstance.delete(url);
        case "patch":
            return postLoginInstance.patch(url, data);
        default:
            return postLoginInstance;
    }
}
