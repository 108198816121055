import React from 'react';
import { Footer, Header } from '../layout/Layout';
import Main from './components/Main';

const ComponentName = props => {
    return (<>
        <div className="contianer overflow-scroll  terms-wrapper">
            <Header className={"terms-header"} />
            <Main className={"terms-main container"} />

        </div >
        <Footer />
    </>
    );
};

export default ComponentName;