import React, { useEffect } from "react";
import { fetchServiceDetails } from "../../../app/home/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";

const HomeServiceDetails = () => {
  const dispatch = useDispatch();
  const { serviceDetails } = useSelector((state) => state.ServiceState);
  useEffect(() => {
    dispatch(fetchServiceDetails());
  }, []);

  return (
    <div className=" home-serivce-details  w-31">
      <div>
        <h3 className="montserrat-semibold f-s-15 mb-3">SERVICE DETAILS</h3>
      </div>
      <Container>
        {serviceDetails && serviceDetails.length > 0 ? (
          serviceDetails?.map((service, idx) => (
            <Container key={idx}>
              <Row>
                <Col xs={12}>
                  <h6 className="montserrat-bold f-s-15">
                    {service.serviceName}{" "}
                  </h6>
                  <p className="montserrat-medium f-s-12">
                    {service.description}
                  </p>
                </Col>
                <Col xs={12} className="service-img-container">
                  <img className="service-img" src={service.imagePath} alt="" />
                </Col>
              </Row>
              {serviceDetails?.length - 1 !== idx && <hr />}
            </Container>
          ))
        ) : (
          <div className="flex m-10 justify-center align-center">
            <Spinner></Spinner>
          </div>
        )}
      </Container>
    </div>
  );
};

export default HomeServiceDetails;
