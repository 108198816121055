import { USE_WALLET, SET_PRICE, SET_PAST_BOOKINGS, SET_UPCOMING_BOOKINGS, SET_SELECTED_DATE, SET_SELECTED_SERVICE, SET_SELECTED_TIME_SLOT, SAVE_BOOKING_UUID_FOR_PAYMENT } from "./ActionTypes";
const INITIAL_STATE = {
    pastBookings: [],
    upcomingBookings: [],
    serviceUuid: null,
    timeSlotUuid: null,
    date: null,
    price: null,
    useWallet: false,
    bookingUuid: null
};
const BookingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SELECTED_DATE:
            return { ...state, date: action.payload };
        case SET_SELECTED_SERVICE:
            return { ...state, serviceUuid: action.payload };
        case SET_SELECTED_TIME_SLOT:
            return { ...state, timeSlotUuid: action.payload };
        case SET_UPCOMING_BOOKINGS:
            return { ...state, upcomingBookings: action.payload };
        case SET_PAST_BOOKINGS:
            return { ...state, pastBookings: action.payload };
        case SET_PRICE:
            return { ...state, price: action.payload };
        case USE_WALLET:
            return { ...state, useWallet: action.payload };
        case SAVE_BOOKING_UUID_FOR_PAYMENT:
            return { ...state, bookingUuid: action.payload };
        default:
            return state
    }
};

export default BookingsReducer