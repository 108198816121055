import React, { useEffect } from "react";
import Input from "../../forms/Input";
import Button from "../../forms/Button";
import { useDispatch, useSelector } from "react-redux";
import { saveRegisterData, showModal } from "../../../app/home/HomeAction";
import { toast } from "react-toastify";
const PersonalDetails = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const { emailAddress } = useSelector((state) => state.LoginState);

  const handlePhoneChange = (e) => {
    const value = e.target.value;

    // Check if the new value is a number
    if (/^\d*$/.test(value)) {
      setPhone(value);
      setPhoneError(""); // Clear any previous error if the input is valid
    } else {
      // Do not update the phone state if the input is not a number
      setPhoneError("Please enter a valid phone number.");
    }
    if (phone.length > 10) {
      setPhoneError("Phone number should be 10 digits");
    }
  };

  const handleSaveUser = () => {
    if (!phone || phone.length < 10 || phone.length > 10) {
      toast.error("Please enter valid phone number");
      return;
    }
    if (!firstName || !lastName) {
      toast.error("Please enter first name and last name");
      return;
    }

    if (!emailAddress) {
      toast.error("Try again after sometime");
      return;
    }

    dispatch(
      saveRegisterData({
        firstName,
        lastName,
        emailAddress,
        phone,
      })
    );
    dispatch(showModal(null));
  };
  return (
    <div className="flex-col w-28 home-personal-details justify-content-center gap-md">
      <div>
        <h3 className="montserrat-semibold f-s-14">PERSONAL DETAILS</h3>
      </div>
      <div className="flex-col gap-md">
        <Input
          important={true}
          heading={"First Name"}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        ></Input>
        <Input
          important={true}
          heading={"Last Name"}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        ></Input>
        <Input
          important={true}
          type={"number"}
          heading={"Phone Number"}
          error={<p className="red-CE2 f-s-12 p-2">{phoneError}</p>}
          value={phone}
          onChange={handlePhoneChange}
        ></Input>
      </div>
      <div>
        <Button
          className={"bg-blue-211 f-s-15  white round-10 montserrat-semibold"}
          onClick={handleSaveUser}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PersonalDetails;
