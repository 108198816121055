import React, { useState } from "react";
import DateSlot from "../../../app/home/components/DateSlot";
import Button from "../../forms/Button";
import { hideModal, showModal } from "../../../app/home/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelBooking,
  changeUpcomingBooking,
} from "../../../app/bookings/BookingsAction";

const Components = () => {
  const dispatch = useDispatch();
  const { date, timeSlotUuid } = useSelector((state) => state.BookingsState);
  const { uuid } = useSelector((state) => state.ServiceState);

  const handlechangeDate = () => {
    dispatch(changeUpcomingBooking({ uuid, date, timeslotUuid: timeSlotUuid }));
  };

  return (
    <div className="flex-col bookings-change-date gap-lg">
      <div>
        <h6 className="montserrat-semibold f-s-14">Change Date</h6>
        <p className="montserrat-medium f-s-14">
          Select the new date and time you wish to change your booking to
        </p>
      </div>
      <div>
        <DateSlot
          style={{
            padding: " 2.6rem 1.5rem",
            paddingBottom: "0",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
          }}
        ></DateSlot>
      </div>
      <div className="flex gap-md">
        <Button
          className={
            "bg-blue-211 white montserrat-semibold f-s-15 round-10 border-transparent"
          }
          onClick={handlechangeDate}
        >
          Confirm
        </Button>
        <Button
          className={"blue-211 border-transparent bg-white"}
          onClick={() => dispatch(hideModal())}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default Components;
