import React from "react";

const Main = ({ className }) => {
  return (
    <div className={className}>
      <h1>Terms and Conditions</h1>

      <h2>1. General</h2>
      <p>
        1.1 These terms and conditions govern the cancellation of bookings made
        through our platform.
      </p>
      <p>
        1.2 By making a booking, you agree to be bound by these terms and
        conditions.
      </p>
      <p>
        1.3 No refunds will be issued for any cancellations. Only credits for
        future bookings may be provided under specific conditions outlined
        below.
      </p>
      <p>
        Cancellations made at least 8 hours prior to the scheduled arrival time
        will receive a credit for the total booking amount to be used for a
        future booking.
      </p>

      <h2>2. Credit for Future Bookings</h2>
      <p>
        2.1 Credits issued for future bookings will be valid for 12 months from
        the date of cancellation.
      </p>
      <p>
        2.2 Credits are non-transferable and can only be used by the original
        booking party.
      </p>
      <p>2.3 Any unused credit after the 12-month period will be forfeited.</p>

      <h2>3. Modifications to Bookings</h2>
      <p>3.1 Modifications to your booking are subject to availability.</p>
      <p>
        3.2 If you modify your booking and then subsequently cancel, the
        cancellation terms applicable will be based on the original booking
        date.
      </p>

      <h2>4. Contact Us</h2>
      <p className="contact-info">
        4.1 If you have any questions about our cancellation policy, please
        contact us at:
        <br />
        Email:{" "}
        <a href="mailto:recoveryzonewollongong@gmail.com">
          recoveryzonewollongong@gmail.com
        </a>
        <br />
        Mobile: <a href="tel:0494074876">0494 074 876</a>
      </p>

      <p>
        By proceeding with a booking, you acknowledge that you have read,
        understood, and agree to these terms and conditions.
      </p>
    </div>
  );
};

export default Main;
