import React from "react";

const SlotPicker = ({ options, selected, onClick, style, className }) => {
  return (
    <div
      className="flex slot-picker-wrapper gap-md overflow-scroll"
      style={{ maxHeight: "18rem", flexWrap: "wrap", ...style }}
    >
      {options &&
        options[0] &&
        options.map((i) => {
          return (
            <div
              style={{
                minWidth: "5rem",
                maxWidth: "5rem",
                padding: "0.6rem 0.3rem",
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
              className={`${
                i.uuid === selected
                  ? "bg-purple-E3D border-purple-E3D montserrat-semibold"
                  : "border-grey-D8D"
              } rounded-sm  pointer montserrat-regular white-space-nowrap f-s-14
                ${
                  i.available ? "" : "bg-grey-D8D grey-808 cursor-disabled"
                } ${className}`}
              key={i.uuid}
              onClick={() => {
                if (i.available) onClick(i.uuid);
              }}
            >
              {i.time}
            </div>
          );
        })}
    </div>
  );
};

export default SlotPicker;
