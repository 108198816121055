import { toast } from "react-toastify"
import { CLEAR_ERRORS, SET_TIME_SLOTS } from "../home/ActionTypes"
import { SAVE_BOOKING_UUID_FOR_PAYMENT, SAVE_PAYMENT_URL, SET_PAST_BOOKINGS, SET_PRICE, SET_SELECTED_DATE, SET_SELECTED_SERVICE, SET_SELECTED_TIME_SLOT, SET_UPCOMING_BOOKINGS, USE_WALLET } from "./ActionTypes"
import { commonPreApi, commonPostApi } from "./Api"
import { getWalletBalance, showModal } from "../home/HomeAction"

export const setSelectedService = (uuid, price) => (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_SERVICE, payload: uuid })
    dispatch(setSelectedTimeSlot(null));
    dispatch({ type: SET_PRICE, payload: price })
    dispatch(fetchTimeSlotsforDate(getState().BookingsState.date, uuid));
}
export const setSelectedDate = (date) => (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_DATE, payload: date })
    dispatch(setSelectedTimeSlot(null));
    dispatch(fetchTimeSlotsforDate(date, getState().BookingsState.serviceUuid));
}
export const setSelectedTimeSlot = (timeSlot) => (dispatch) => {
    dispatch({ type: SET_SELECTED_TIME_SLOT, payload: timeSlot })
}

export const fetchTimeSlotsforDate = (date = "", serviceUuid) => async (dispatch) => {
    dispatch({ type: SET_TIME_SLOTS, payload: { list: [] } })
    try {
        let url = `/timeslot`;
        const params = [];
        if (date) {
            params.push(`date=${encodeURIComponent(date)}`);
        }
        if (serviceUuid) {
            params.push(`serviceUuid=${serviceUuid}`);
        }
        if (params.length > 0) {
            url += `?${params.join("&")}`;
        }
        const res = await commonPreApi('get', url);
        dispatch({ type: SET_TIME_SLOTS, payload: res.data });
    } catch (error) {
        toast.error("Failed to fetch time slots. Please reload!");
        dispatch({ type: SET_TIME_SLOTS, payload: { list: [] } });
        dispatch({ type: CLEAR_ERRORS });
    }
};

export const fetchTimeSlots = () => async (dispatch) => {
    try {
        const res = await commonPreApi('get', `/timeslot`);
        dispatch({ type: SET_TIME_SLOTS, payload: res.data });
    } catch (error) {
        toast.error("Failed to fetch time slots Reload!");
        dispatch({ type: SET_TIME_SLOTS, payload: { list: [] } });
        dispatch({ type: CLEAR_ERRORS });
    }
}

export const fetchPastBookings = () => async (dispatch) => {
    try {
        const { data } = await commonPostApi('get', `/bookings/past-bookings`);
        dispatch({ type: SET_PAST_BOOKINGS, payload: data.list });
    }
    catch (error) {
        toast.error("Failed to fetch past bookings Reload!");
        dispatch({ type: CLEAR_ERRORS });
    }
}
export const fetchUpcomingBookings = () => async (dispatch) => {
    try {
        const { data } = await commonPostApi('get', `/bookings/upcoming-bookings`);
        dispatch({ type: SET_UPCOMING_BOOKINGS, payload: data.list });
    }
    catch (error) {
        toast.error("Failed to fetch upcoming bookings Reload!");
        dispatch({ type: CLEAR_ERRORS });
    }
}
export const changeUpcomingBooking = (data) => async (dispatch) => {
    try {
        await commonPostApi('patch', `/bookings/${data.uuid}`, {
            date: data.date,
            timeslotUuid: data.timeslotUuid
        });
        dispatch(fetchUpcomingBookings())
        dispatch(showModal(null))
        toast.success("Booking changed successfully!");
    }
    catch (error) {
        toast.error("Failed to change booking!");
    }
}

export const cancelBooking = (uuid) => async (dispatch) => {
    try {
        await commonPostApi('patch', `/bookings/cancel-booking/${uuid}`);
        dispatch(fetchUpcomingBookings());
        dispatch(getWalletBalance());
        dispatch(showModal(null));
    }
    catch (error) {
        toast.error("Failed to delete booking!");
    }
}

export const setUseWallet = (value) => (dispatch) => {
    dispatch({ type: USE_WALLET, payload: value })
}

export const createBooking = (data) => async (dispatch) => {
    try {
        const res = await commonPostApi('post', '/bookings', {
            serviceUuid: data?.serviceUuid,
            timeSlotUuid: data?.timeSlotUuid,
            date: data?.date,
            isWallet: data?.isWallet,
            userUuid: data?.userUuid,
            status: 'upcoming'
        });
        dispatch({ type: SAVE_BOOKING_UUID_FOR_PAYMENT, payload: res.data.uuid });
        dispatch(getWalletBalance());
    } catch (error) {
        toast.error("Failed to create booking!");
    }
}
