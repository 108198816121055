import Home from "./app/home/Home"
import Bookings from "./app/bookings/Bookings"
import Payments from "./app/payment/Payment"
import Terms from "./app/terms/Terms"

import ProtectedRoute from "./utils/PrivateRoute"

const routes = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/terms-conditions",
        element: <Terms />,
    },
    {
        path: "/bookings",
        element: <ProtectedRoute><Bookings /></ProtectedRoute>
    },
    {
        path: "/payments/:bookingUuid",
        element: <ProtectedRoute><Payments /></ProtectedRoute>
    }

]
export default routes