import React, { useEffect } from "react";
import Input from "../../forms/Input";
import Button from "../../forms/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserProfile,
  fetchUserProfile,
  showModal,
} from "../../../app/home/HomeAction";
import { toast } from "react-toastify";
const PersonalDetails = () => {
  const dispatch = useDispatch();
  const {
    firstName: fn,
    lastName: ln,
    phone: ph,
  } = useSelector((state) => state.UserState);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const handleChangeProfile = () => {
    if (!firstName || !lastName || !phone) {
      return toast.info("Please fill all the fields");
    }
    dispatch(
      changeUserProfile({
        firstName,
        lastName,
        phone,
      })
    );
    dispatch(fetchUserProfile());
    dispatch(showModal(null));
  };

  useEffect(() => {
    setFirstName(fn);
    setLastName(ln);
    setPhone(ph);
  }, []);
  return (
    <div className="flex-col w-28 home-personal-details justify-content-center gap-md">
      <div>
        <h3 className="montserrat-semibold f-s-14">PERSONAL DETAILS</h3>
      </div>
      <div className="flex-col gap-md">
        <Input
          important={true}
          heading={"First Name"}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        ></Input>
        <Input
          important={true}
          heading={"Last Name"}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        ></Input>
        <Input
          important={true}
          heading={"Phone Number"}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        ></Input>
      </div>
      <div>
        <Button
          className={"bg-blue-211 f-s-15  white round-10 montserrat-semibold"}
          onClick={handleChangeProfile}
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default PersonalDetails;
