import React from "react";
import Button from "../../forms/Button";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../app/home/HomeAction";
import { cancelBooking } from "../../../app/bookings/BookingsAction";

const ComponentName = () => {
  const dispatch = useDispatch();
  const { uuid } = useSelector((state) => state.ServiceState);
  return (
    <div className="flex flex-col bookings-cancel-booking gap-md justify-between">
      <div className="montserrat-medium">
        <h3 className="montserrat-semibold f-s-14">Cancel Booking</h3>
        <p className="f-s-14">
          You are about to cancel your session. Your payment amount will be
          converted into points, which you can use for future bookings. One
          dollar equals one point.
        </p>
        <p className="f-s-14">
          Please, tell us why you are cancelling your session{" "}
        </p>
      </div>
      <div className="">
        <textarea
          className=" bg-grey-F4F montserrat-regular"
          placeholder="Please type here.."
          rows={3}
        ></textarea>
      </div>
      <div className="flex gap-sm">
        <Button
          className={"bg-blue-211 white round-10"}
          onClick={() => dispatch(cancelBooking(uuid))}
        >
          Submit
        </Button>
        <Button
          className={"bg-transparent border-transparent"}
          onClick={() => dispatch(showModal(null))}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ComponentName;
